<template>
<div class="wx-help">
  <p class="tit">定制小程序使用教程</p>
  <p>1.在微信公众平台注册微信小程序，或在已认证的公众号后台使用资质快速注册小程序。 微信小程序注册地址： https://mp.weixin.qq.com/</p>
  <p>2.完善小程序信息，如名称、图标、描述等</p>
  <p>3. 修改服务类目，服务类目是小程序审核的重点，必须要添加“教育-在线教育”类目，否则审核时可能会失败</p>
  <p class="tit">已有小程序，立即授权</p>
  <p>1. 登录SaaS后，点击左侧【微信小程序定制】，输入正确的APPID、AppSecret。</p>
  <p>2. 点击立即授权，用手机微信扫描二维码。</p>
  <img src="../../assets/images/wx/sq.png" />
  <p>扫描之后，手机微信上会显示如下，选择一个需要授权的账号。</p>
  <img src="../../assets/images/wx/xzzh.png" />
  <p>选择授权选项，默认全选，后期也可更新选项，确认授权。</p>
  <img src="../../assets/images/wx/sqqr.png" />
  <p>3. 点击提交，等待审核，审核时间一般需要1-7天时间。</p>
  <img src="../../assets/images/wx/wx_tj.png" class="ht" />
  <p>4.审核成功后，等待发布。发布成功后，线上小层序自动成效，直接将小程序二维码发给您的用户即可。</p>
  <img src="../../assets/images/wx/wx_sh.png" class="ht" />
<!--  <p>5.小程序发布后，可以在【企业信息】中，修改企业相关信息，这些信息，将在小程序中显示。</p>-->
</div>
</template>

<script>
export default {
  name: "help.vue"
}
</script>

<style scoped lang="scss">
.wx-help{
  background-color: #FFFFFF;
  padding: 20px;
  line-height: 26px;
  font-size: 14px;

  .tit{
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ht{
    width: 800px;
  }
}
</style>
